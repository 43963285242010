<template>
    <BaseSection
        class="base-section--right-bleed"
        :class="{
            'base-section--align-left': !prefiltered,
        }"
    >
        <template
            v-if="pending || !!$slots.title"
            #title
        >
            <template v-if="pending">
                <Skeletor />
            </template>
            <slot
                v-else
                name="title"
            />
        </template>

        <template
            v-if="pending || !!$slots.intro"
            #intro
        >
            <template v-if="pending">
                <Skeletor />
                <Skeletor />
            </template>
            <slot
                v-else
                name="intro"
            />
        </template>

        <div
            class="property-list"
            :class="{
                'property-list--prefiltered': prefiltered
            }"
        >
            <div class="property-list__filters">
                <BaseFilters
                    v-if="!prefiltered"
                    :active-filters="[activeFilter]"
                    :items="citiesWithOpenProperties"
                    :pending="pending"
                    :hide-small="true"
                    @update:active-filter="activeFilter = $event; phaseFilter = ['comingSoon', 'open']"
                    @update:clear-filters="activeFilter = null"
                >
                    <template #all-filters-wrapper="{activeFilters}">
                        <BaseDotLink
                            :active="phaseFilter.includes('open') && (activeFilters?.length === 0 || activeFilters?.includes(null))"
                            @click="phaseFilter = ['coming-soon', 'open']; clearFilters()"
                        >
                            {{ $t('all-locations') }}
                        </BaseDotLink>
                    </template>
                </BaseFilters>
                <BaseFilters
                    v-if="!prefiltered"
                    :active-filters="[activeFilter]"
                    :items="citiesWithClosedProperties"
                    :pending="pending"
                    :hide-small="true"
                    @update:active-filter="activeFilter = $event; phaseFilter = ['inDevelopment']"
                    @update:clear-filters="activeFilter = null"
                >
                    <template #all-filters-wrapper="{activeFilters}">
                        <BaseDotLink
                            :active="phaseFilter.includes('inDevelopment') && (activeFilters?.length === 0 || activeFilters?.includes(null))"
                            @click="phaseFilter = ['inDevelopment']; clearFilters()"
                        >
                            {{ $t('in-development') }}
                        </BaseDotLink>
                    </template>
                </BaseFilters>
            </div>
            <PropertyListSlider
                v-if="data?.propertiesEntries"
                :items="data?.propertiesEntries"
                :pending="pending || pendingFilters"
            />
            <!-- CLONED FILTERS FOR OVERLAY EFFECT -->
            <div class="property-list__filters property-list__filters--cloned">
                <BaseFilters
                    v-if="!prefiltered"
                    :active-filters="[activeFilter]"
                    :items="citiesWithOpenProperties"
                    :pending="pending"
                    @update:active-filter="activeFilter = $event; phaseFilter = ['comingSoon', 'open']"
                    @update:clear-filters="activeFilter = null"
                >
                    <template #all-filters-wrapper="{activeFilters}">
                        <BaseDotLink
                            :active="phaseFilter.includes('open') && (activeFilters?.length === 0 || activeFilters?.includes(null))"
                            @click="phaseFilter = ['comingSoon', 'open']; clearFilters()"
                        >
                            {{ $t('all-locations') }}
                        </BaseDotLink>
                    </template>
                </BaseFilters>
                <BaseFilters
                    v-if="!prefiltered"
                    :active-filters="[activeFilter]"
                    :items="citiesWithClosedProperties"
                    :pending="pending"
                    @update:active-filter="activeFilter = $event; phaseFilter = ['inDevelopment']"
                    @update:clear-filters="activeFilter = null"
                >
                    <template #all-filters-wrapper="{activeFilters}">
                        <BaseDotLink
                            :active="phaseFilter.includes('inDevelopment') && (activeFilters?.length === 0 || activeFilters?.includes(null))"
                            @click="phaseFilter = ['inDevelopment']; clearFilters()"
                        >
                            {{ $t('in-development') }}
                        </BaseDotLink>
                    </template>
                </BaseFilters>
            </div>
        </div>
    </BaseSection>
</template>

<script setup>
import { defineProps } from 'vue';
import propertyListQuery from '~/graphql/queries/propertyList.graphql';

const props = defineProps({
    pending: {
        type: Boolean,
        default: false
    },
    cities: {
        type: Array,
        default: () => []
    },
    fixedFilter: {
        type: [String, Number],
        default: null
    }
});

const prefiltered = computed(() => {
    return props.fixedFilter !== null;
});

const activeFilter = ref(null);
watch(() => props.fixedFilter, () => {
    activeFilter.value = props.fixedFilter;
}, { immediate: true });

const phaseFilter = ref(['comingSoon', 'open']);

if (prefiltered.value) {
    phaseFilter.value = ['inDevelopment', 'comingSoon', 'open'];
}

const { variables } = useCraftGraphql();
variables.city = activeFilter;
variables.phase = phaseFilter;
delete variables.uri;

const { data: allProperties } = await useLazyAsyncQuery({
    query: propertyListQuery,
    variables: {
        site: variables.site,
        city: null
    }
});

const { data, pending: pendingFilters } = await useLazyAsyncQuery({
    query: propertyListQuery,
    variables: variables
});

// onMounted(() => {
//     allProperties.value = toValue(data?.value?.propertiesEntries);
// });

const openProperties = computed(() => { return allProperties?.value?.propertiesEntries.filter(property => property.propertyPhase !== 'inDevelopment');});
const closedProperties = computed(() => { return allProperties?.value?.propertiesEntries.filter(property => property.propertyPhase === 'inDevelopment');});


const citiesWithOpenProperties = computed(() => {
    const results = [];
    return openProperties?.value?.map(property => {
        if (results.includes(property.city[0].id)) {
            return false;
        }

        results.push(property.city[0].id);

        return {
            title: property.city[0].title,
            id: property.city[0].id,
            slug: property.city[0].slug
        };
    }).filter(item => item);
});

const citiesWithClosedProperties = computed(() => {
    const results = [];
    return closedProperties.value.map(property => {
        if (results.includes(property.city[0].id)) {
            return false;
        }

        results.push(property.city[0].id);

        return {
            title: property.city[0].title,
            id: property.city[0].id,
            slug: property.city[0].slug
        };
    }).filter(item => item);
});

const clearFilters = () => {
    activeFilter.value = null;
};
</script>

<style lang="less">
.property-list {
    // stylelint-disable-next-line length-zero-no-unit
    --filters-width: 0rem;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    width: 100%;

    .base-slider {
        justify-content: flex-start;
    }

    @media @q-md-min {
        --filters-width: 15rem;

        gap: var(--spacing-lg);
        flex-direction: row;
    }
}

.property-list__filters {
    display: flex;
    @media @q-md-min {
        flex-direction: column;
        position: absolute;
        z-index: 0;
        flex: 1 0 auto;
        gap: 3.75rem;

        width: var(--filters-width);
    }
}

.property-list__filters--cloned {
    display: none;

    @media @q-md-min {
        display: flex;
        opacity: 0;
        z-index: 10;

        transition: opacity var(--transition-timing-medium) var(--transition-timingFunction-in-out);

        &:hover {
            opacity: 1;
        }
    }
}

.property-list--prefiltered {
    // stylelint-disable-next-line length-zero-no-unit
    --filters-width: 0rem;

    .base-slider {
        justify-content: center;
    }
}
</style>
